<template>
	<a-modal v-model="staffFlag" title="请选择" ok-text="确认" cancel-text="取消" @ok="assignHideModal" @cancel="cancelAssign">
		<a-input-search v-model="staffname" placeholder="根据员工名称搜索" enter-button="搜索" size="large" @search="staffSearch"  style="margin-bottom:20px"/>
		
		<div class="flex">
			<a-tree class="trees" @select="onSelect" @expand="onExpand" :tree-data="treeData" :defaultSelectedKeys="[]"/>
			<a-list class="lists" item-layout="horizontal" :data-source="handlerList" bordered >
				<a-list-item slot="renderItem" slot-scope="item,index" :style="item.isChecked ? 'background:#BAE7FF;':'background:#fff;'" @click="staffCheck(item,index)">
					<img style="width:40px;margin-right:10px;border-radius:50%;" :src="item.avatar"/>{{item.name}}
				</a-list-item>
			</a-list>
		</div>
	</a-modal>
</template>
<script>
	import {requestXml} from '../assets/js/request';
	export default {
		props: {
			staffFlag: {
				type: Boolean,
				default: true
			},
		}, 
		data() {
			return {
				// 分配客户
				visible: true,
				staff: "",
				handlerList: [],

				department_id: "",
				treeData : [],
				staffname: "",
				
			};
		},
		mounted: function() {
			this.getDepart();
			this.getMinList();
		},
		methods: {
			// 获取部门
			getDepart(){
				requestXml("/scrm/Index/getDepartment","GET",(res) => {
					this.treeData = res;
				})
			},
			// 获取员工列表
			getMinList(){
				requestXml("/scrm/Staff/getMinList", "POST", (res) => {
					for(let i=0;i<res.length;i++){
						res[i].isChecked = false;
						res[i].department_id=this.department_id;
					}
					this.handlerList = res;
				},{"key":this.staffname,"department_id": this.department_id})
			},
			staffCheck(e,idx){
				for(let i=0;i<this.handlerList.length;i++){
					this.handlerList[i].isChecked = false;
				}
				this.handlerList[idx].isChecked = true;

				this.staff= this.handlerList[idx];
				this.$set(this.handlerList,idx,this.handlerList[idx]);
			},
			staffSearch(){
				this.department_id = "";
				this.getMinList();
			},
			onSelect(keys) {
				this.staffname = "";
				this.department_id = keys[0];
				this.getMinList();
				// this.getStaffList();
			},
			onExpand(keys) {
				console.log('Trigger Expand',keys);
			},
			assignHideModal() {
				this.$emit('updateStaffFlag', this.staff);
				
			},
			cancelAssign(){
				this.$emit('updateStaffFlag', 1);
			}
        }
    }

</script>
<style scoped>
	
    .trees .ant-tree-treenode-switcher-open .ant-tree-node-content-wrapper .ant-tree-title{
		display: inline-block !important;
		width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
    }
	.trees{
		width: 55%;
	}
	.lists{
		width: 45%;
		max-height: 500px;
		overflow-y: scroll;
		margin-left: 15px;
	}
	@media screen and (min-width: 750px) {
		.trees{
			width: 50%;
		}
		.lists{
			width: 50%;
		}
	}
</style>