<!--
 * @Descripttion: 
-->
<template>
    <!-- <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
    <div class="title">新建 <img src="@/assets/imgs/close.png" alt="" @click="hideMask"></div> -->
    <div class="content">
         <div class="flex"><h4 class="global_head_title">新建/编辑个人目标</h4></div>
        <div>
            <!-- <div class="content_row flex">
                <label class="info" for="">选择类型</label>
                <div class="content_right">
                    <a-radio-group v-model="targetType">
                        <a-radio-button
                            v-for="(item, index) in targetTypeArr"
                            :key="index"
                            :value="item.key"
                        >
                            {{ item.value }}
                        </a-radio-button>
                    </a-radio-group>
                </div>
            </div> -->
            <div class="content_row flex" v-if="targetType == 3">
                <label class="info" for="">选择执行人<span class="redS">*</span></label>
                <div class="content_right">
                    <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择执行人" />
                    <!-- <a-select
                        size="large"
                        v-model="executor"
                        placeholder="请选择执行人"
                    >
                        <a-select-option
                            v-for="(item, index) in clientList"
                            :key="index"
                            :value="item.userid"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select> -->
                </div>
            </div>
            <div class="content_row flex" v-if="targetType == 2">
                <label class="info" for="">选择部门<span class="redS">*</span></label>
                <div class="content_right">
                    <a-select
                        size="large"
                        v-model="teamor"
                        placeholder="请选部门"
                    >
                        <a-select-option
                            v-for="(item, index) in teamList"
                            :key="index"
                            :value="item.did"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">目标周期<span class="redS">*</span></label>
                <div class="content_right">
                    <a-select
                        size="large"
                        v-model="client"
                        placeholder="请选择要添加的目标周期"
                    >
                        <a-select-option
                            v-for="(item, index) in timeType"
                            :key="index"
                            :value="item.key"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">时间<span class="redS">*</span></label>
                <div class="content_right">
                    <!-- 选择年份 -->
                    <a-select
                        v-if="client == 1"
                        v-model="yearSelect"
                        aria-placeholder="请选择年份"
                    >
                        <a-select-option
                            v-for="(item, index) in yearArr"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>

                    <!-- 选择月份 -->
                    <a-month-picker
                        v-if="client == 3"
                        v-model="monthSelect"
                        class="inpWidth"
                        placeholder="请选择月份"
                    >
                    </a-month-picker>
                    <!-- 选择周 -->
                    <!-- <a-input @focus="blur" :value="endOpen + ''"></a-input> -->
                    <a-week-picker
                        style="width: 400px"
                        v-if="client == 4"
                        @change="weekChange"
                        class="inpWidth"
                        placeholder="请选择周"
                        v-model="createWeek"
                    >
                        <span>
                            <a-input v-model="createText"></a-input>
                        </span>
                    </a-week-picker>

                    <!-- 选择季度 -->
                    <div v-if="client == 2" class="inpWidth dis-bwe">
                        <a-select
                            style="width: 180px"
                            v-model="timeSelect"
                            aria-placeholder="请选择年份"
                        >
                            <a-select-option
                                v-for="(item, index) in yearArr"
                                :key="index"
                                :value="item"
                            >
                                {{ item }}
                            </a-select-option>
                        </a-select>
                        <a-select
                            style="width: 180px"
                            v-model="timeSelect"
                            aria-placeholder="请选择季度"
                        >
                            <a-select-option
                                v-for="(item, index) in [
                                    '第一季度',
                                    '第二季度',
                                    '第三季度',
                                    '第四季度',
                                ]"
                                :key="index"
                                :value="item"
                            >
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>

            <!-- <div class="content_row flex" style="align-items: normal">
                <label class="info" for="">新客数</label>
                <div class="content_right">
                    <a-input-number
                        v-model="signlistnums"
                        class="inpWidth"
                        placeholder="请输入新客数"
                    >
                    </a-input-number>
                </div>
            </div> -->

            <!-- <div class="content_row flex" style="align-items: normal">
                <label class="info" for="">签单数</label>
                <div class="content_right">
                    <a-input-number
                        v-model="signlistnum"
                        class="inpWidth"
                        placeholder="请输入签单数"
                    ></a-input-number>
                </div>
            </div> -->
            <div class="content_row flex" style="align-items: normal">
                <label class="info" for="">开票金额</label>
                <div class="content_right">
                    <a-input-number
                        v-model="signlistmoney"
                        class="inpWidth"
                        placeholder="请输入开票金额"
                    ></a-input-number>
                </div>
            </div>
            <!-- <div class="content_row flex" style="align-items: normal">
                <label class="info" for="">回款数</label>
                <div class="content_right">
                    <a-input-number
                        v-model="paymentnum"
                        class="inpWidth"
                        placeholder="请输入回款数"
                    ></a-input-number>
                </div>
            </div> -->
            <div class="content_row flex" style="align-items: normal">
                <label class="info" for="">回款金额</label>
                <div class="content_right">
                    <a-input-number
                        v-model="paymentmoney"
                        class="inpWidth"
                        placeholder="请输入回款金额"
                    ></a-input-number>
                </div>
            </div>

            <div class="save">
                <a-button type="primary" @click="submit">保存</a-button>
            </div>
        </div>
         <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
    </div>
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import { requestXml, getDateTime, isMobile } from "@/assets/js/request";
var moment = require("moment");
let yearArr = [];
var myDate = new Date();
let Y =myDate.getFullYear();
for (let i = Y; i < 2100; i++) {
    yearArr.push(i);
}
export default {
    components: {
        Staff,
    },
    data() {
        return {
            targetTypeArr: [
                // {
                //     key: 1,
                //     value: "公司目标",
                // },
                // {
                //     key: 2,
                //     value: "团体目标",
                // },
                {
                    key: 3,
                    value: "个人目标",
                },
            ],
            // (1=>公司目标,2=>团体目标,3=>个人目标
            targetType: 3,

            createTime: null, //目标创建时间
            endtime: null, //目标结束时间
            createText: "",
            executor: undefined, //执行人
            staffFlag: false,
            onStaff: {
                name: "",
                userid: "",
            },
            teamor: "", //团体id
            teamList: [], //团体列表

            client: 1, //目标类型

            createWeek: null, //周选择 中间变量
            yearSelect: null, //年选择 中间变量
            monthSelect: null, //月选择 中间变量

            yearArr,
            timeSelect: "",
            timeType: [
                { label: "年", key: 1 },
                // { label: "季度", key: 2 },
                { label: "月", key: 3 },
                { label: "周", key: 4 },
            ],
            signlistnums: 0, //新客
            signlistnum: 0, //签单
            paymentnum: 0, //回款数
            signlistmoney: '', //签单金额
            paymentmoney: '', //回款
            type: 0,
            desc: "",
            date: [],
            start: "",
            end: "",

            clientList: [],

            mode: 1,
            staff_uid: "default",
            handlerList: [],

            // 任务id
            id: "",
            taskList: [],
        };
    },
    mounted: function () {
        this.getClientList();
        // this.getStaffList();
        this.id = this.$route.query.id;
        if (this.id) {
            this.getTaskDetail();
        }
    },
    watch: {
        // client(val){
        //     if(val){
        //         this.createWeek = '132'
        //     }
        // },
        taskId: function (newVal) {
            this.id = newVal;
        },
         yearSelect(val) {
            if (val) {
                this.createTime = moment(val + "-01-01").unix()+10;
                this.endtime = moment(val+1 + "-01-01").unix()-10;
                console.log("this.endtime", this.createTime, this.endtime);
            }
        },
        monthSelect(val) {
            if (val) {
                const times = moment(val.format("YYYY-MM-DD"));
                this.createTime = times.subtract(times.dates() - 1, "d").unix();
                this.endtime = times.add(1, "M").unix();
                console.log(
                    "this.createTime",
                    val.dates(),
                    this.createTime,
                    this.endtime
                );
            }
        },
    },
    methods: {
        blur() {
            this.endOpen = !this.endOpen;
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
          // 负责人-输入框聚焦是清除其焦点
        focus(e) {
            e.srcElement.blur();
        },
        // 分配完
        updateStaffFlag(data) {
        // console.log(data,"dasds")
        if (data != 1) {
            this.onStaff = data;
        }
            this.staffFlag = false;
        },
        weekChange(date, dateString) {
            console.log("周变化触发");
            let start = {
                s: moment(+date).format("YYYY-MM-DD"),
                e: date.format("YYYY-MM-DD"),
            };
            console.log("start", start);
            // const start = moment(date.format("YYYY-MM-DD"));
            function Person(s, e) {
                this.s = moment(s);
                this.e = moment(e);
            }
            let sum = new Person(start.s, start.e);
            let dataS = "";
            let dataE = "";
            dataS = sum.s.day(1);
            dataE = sum.e.day(7);

            this.createTime = +dataS /1000;
            this.endtime = +dataE /1000;
            console.log("this.createTime", this.createTime, this.endtime);
            this.createText =
                dataS.format("YYYY-MM-DD") +
                "到" +
                dataE.format("YYYY-MM-DD") +
                "(" +
                dateString.replace("-", "第") +
                ")";
        },
        // 获取 简化成员列表
        getClientList() {
            requestXml("/scrm/Staff/getMinList", "POST", (res) => {
                this.clientList = res;
            });
            requestXml("/jieb/Target/department", "POST", (res) => {
                this.teamor = res[0].did;
                this.teamList = res;
            });
        },
        // 获取处理人
        getStaffList() {
            requestXml("/scrm/Staff/getInfo", "GET", (res) => {
                this.handlerList = res;
                // console.log(this.handlerList)
            });
        },
        // 获取任务详情
        getTaskDetail() {
            requestXml(
                "/scrm/Task/getDetails",
                "GET",
                (res) => {
                    console.log(res);
                    this.type = res.type + "";
                    this.desc = res.task_content;
                    this.start = new moment(getDateTime(res.start, 3));
                    this.end = new moment(getDateTime(res.end, 3));
                    this.date[0] = this.start;
                    this.date[1] = this.end;
                    for (let i = 0; i < res.list.length; i++) {
                        this.client.push(res.list[i].ids);
                    }
                    console.log(this.client);
                    this.staff_uid = res.staff_uid;
                },
                { id: this.id }
            );
        },
        // 添加任务
        submit() {
            let target = this.timeType.find((item) => {
                if (item.key == this.client) {
                    console.log("item.label", item.label);
                    return item.label;
                }
            });

            let postdata = {
                newcustomers: this.signlistnums,
                signlistnum: this.signlistnum,
                signlistmoney: this.signlistmoney,
                paymentnum: this.paymentnum,
                paymentmoney: this.paymentmoney,
                timetype: target.label,
                staff_id: "",
                createtime: this.createTime,
                endtime: this.endtime,
            };
            console.log("staff_idstaff_idstaff_id", this.signlistnums);

            let url;
            // this.executor
            // teamor
            if ((this.targetType == 2)) {
                url = "/jieb/Target/teamadd";
                postdata.staff_id = this.teamor
            } else if ((this.targetType == 3)) {
                url = "/jieb/Target/personaladd";
                postdata.staff_id = this.onStaff.userid
            }
            console.log(isMobile());
            requestXml(
                url,
                "POST",
                (res) => {
                    if(res!= 'error'){
                        this.$message.success('目标创建成功')
                        this.$router.push({
                            path: '/scrm_pc/target/my',
                        });
                    }
                    console.log('res',res);
                },
                { ...postdata }
            );
        },
    },
};
</script>
<style lang='less' scoped>
@inp_with: 400px;
.content .content_row {
    align-items: center;
    margin: 2rem 0;
}
.inpWidth {
    width: (@inp_with + 2) !important;
}
.dis-bwe {
    display: flex;
    justify-content: space-between;
}
.content .content_row .content_right {
    width: calc(100% - 15rem);
    text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #36a3ff;
    border-color: #36a3ff;
}
.content .content_row .info {
    display: inline-block;
    width: 15rem;
    text-align: right;
    padding-right: 1rem;
    color: #202020;
    font-weight: normal;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
    width: 100%;
    // background-color: #f9f9f9;
}
.content .content_row .ant-select-selection--multiple input {
    width: 0;
}
.ant-select-selection{
    background-color: #fff;
}
/* 日期 */
// .content .content_row .ant-calendar-picker {
//     width: 100% !important;
// }
// .content .content_row .ant-calendar-picker input {
//     width: 45% !important;
// }

.save {
    text-align: left;
}
.save .ant-btn {
    width: 100px;
    height: 40px;
    background: #36a3ff;
    border-radius: 4px;
    margin-left: 13rem;
}

@media screen and (min-width: 750px) {
    .content .content_row {
        margin: 15px 0;
        font-size: 16px;
    }
    .content .content_row .content_right {
        width: calc(100% - 120px);
    }
    .content .content_row .info {
        width: 90px;
        padding-right: 10px;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker {
        width: 400px !important;
    }
    // .content .content_row .ant-calendar-picker input {
    //     width: 45%;
    // }
    /* input ,selsct */
    .content .content_row input,
    .content .content_row .ant-select {
        width: 400px;
    }
    /* textarea */
    .content .content_row textarea.ant-input {
        width: 60%;
    }
    .save .ant-btn {
        margin-left: 90px;
    }
}
</style>
